<template>
  <g>
    <svg:style>
      .cls-10, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#glass-pattern);
      }

      .cls-6 {
        fill: url(#inox-gradient-2);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-10, .cls-8 {
        stroke-linecap: square;
      }

      .cls-8 {
        fill: url(#inox-gradient-3);
      }

      .cls-9 {
        fill: url(#glass-pattern);
      }

      .cls-10 {
        fill: url(#inox-gradient-4);
      }

      .Solid {
        fill: {{ doorSolidColor }}
      }
      .Molding-L { fill: url(#Molding-L); }
      .Molding-T { fill: url(#Molding-T); }
      .Molding-R { fill: url(#Molding-R); }
      .Molding-B { fill: url(#Molding-B); }
    </svg:style>
    <linearGradient id="handle-gradient"
                    :x1="doorLeftWidth1 + 11.16"
                    :y1="doorTopHeight1 + 148.47"
                    :x2="doorLeftWidth1 + 16.75"
                    :y2="doorTopHeight1 + 148.47"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient"
                    :x1="doorLeftWidth1 + 34.95"
                    :y1="doorTopHeight1 + 148.81"
                    :x2="doorLeftWidth1 + 63.48"
                    :y2="doorTopHeight1 + 148.81"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient"
                    :x1="doorLeftWidth1 + 49.28"
                    :y1="doorTopHeight1 + 199.54"
                    :x2="doorLeftWidth1 + 49.28"
                    :y2="doorTopHeight1 + 98.08"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 76.54"
                    :y1="doorTopHeight1 + 148.81"
                    :x2="doorLeftWidth1 + 104.18"
                    :y2="doorTopHeight1 + 148.81"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-2"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 90.36"
                    :y1="doorTopHeight1 + 199.51"
                    :x2="doorLeftWidth1 + 90.36"
                    :y2="doorTopHeight1 + 98.1"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-3"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 1018.19"
                    :y1="doorTopHeight1 + 7496.18"
                    :x2="doorLeftWidth1 + 1018.19"
                    :y2="doorTopHeight1 + 7468.15"
                    xlink:href="#handle-gradient"/>
    <linearGradient id="glass-gradient-3"
                    data-name="glass-gradient"
                    :x1="doorLeftWidth1 + 69.76"
                    :y1="doorTopHeight1 + 74.37"
                    :x2="doorLeftWidth1 + 69.76"
                    :y2="doorTopHeight1 + 56.17"
                    xlink:href="#glass-gradient"/>
    <linearGradient id="inox-gradient-4"
                    data-name="inox-gradient"
                    :x1="doorLeftWidth1 + 1018.19"
                    :y1="doorTopHeight1 + 7329.03"
                    :x2="doorLeftWidth1 + 1018.19"
                    :y2="doorTopHeight1 + 7300.99"
                    xlink:href="#handle-gradient"/>

    <!-- Molding gradient BEGIN  -->
    <linearGradient id="Molding-L" data-name="Molding" x1="0" y1="0" x2="100%" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-T" data-name="Molding" x1="0" y1="0" x2="0" y2="100%">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-R" data-name="Molding" x1="100%" y1="0" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-B" data-name="Molding" x1="0" y1="100%" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <!-- Molding gradient END -->

    <g id="C7">
      <polyline id="inox"
                v-if="showInox"
                class="cls-4"
                :points="`
                ${doorLeftWidth1 + 34.95} ${doorTopHeight1 + 93.21}
                ${doorLeftWidth1 + 34.95} ${doorTopHeight1 + 204.41}
                ${doorLeftWidth1 + 63.48} ${doorTopHeight1 + 204.41}
                ${doorLeftWidth1 + 63.48} ${doorTopHeight1 + 93.21}
                ${doorLeftWidth1 + 34.95} ${doorTopHeight1 + 93.21}
                `"/>
      <polyline id="glass"
                class="cls-5"
                :points="`
                ${doorLeftWidth1 + 39.95} ${doorTopHeight1 + 98.08}
                ${doorLeftWidth1 + 39.95} ${doorTopHeight1 + 199.54}
                ${doorLeftWidth1 + 58.62} ${doorTopHeight1 + 199.54}
                ${doorLeftWidth1 + 58.62} ${doorTopHeight1 + 98.08}
                ${doorLeftWidth1 + 39.95} ${doorTopHeight1 + 98.08}
                `"/>
      <polyline id="inox-2"
                v-if="showInox"
                data-name="inox"
                class="cls-6"
                :points="`
                ${doorLeftWidth1 + 76.54} ${doorTopHeight1 + 93.21}
                ${doorLeftWidth1 + 76.54} ${doorTopHeight1 + 204.41}
                ${doorLeftWidth1 + 104.18} ${doorTopHeight1 + 204.41}
                ${doorLeftWidth1 + 104.18} ${doorTopHeight1 + 93.21}
                ${doorLeftWidth1 + 76.54} ${doorTopHeight1 + 93.21}
                `"/>
      <polyline id="glass-2"
                data-name="glass"
                class="cls-7"
                :points="`
                ${doorLeftWidth1 + 82.07} ${doorTopHeight1 + 98.1}
                ${doorLeftWidth1 + 82.07} ${doorTopHeight1 + 199.51}
                ${doorLeftWidth1 + 98.65} ${doorTopHeight1 + 199.51}
                ${doorLeftWidth1 + 98.65} ${doorTopHeight1 + 98.1}
                ${doorLeftWidth1 + 82.07} ${doorTopHeight1 + 98.1}
                `"/>
      <path id="inox-3"
            v-if="showInox"
            data-name="inox"
            class="cls-8"
            :d="`
            M${doorLeftWidth1 + 983.31},${doorTopHeight1 + 7468.15}
            v28
            h69.78
            v-28
            H${doorLeftWidth1 + 983.31}
            Z
            m65,23.07
            H${doorLeftWidth1 + 988.12}
            V${doorTopHeight1 + 7473}
            h60.14
            v18.25
            Z`"
            transform="translate(-948.44 -7249.7)"/>
      <polyline id="glass-3"
                data-name="glass"
                class="cls-9"
                :points="`
                ${doorLeftWidth1 + 39.66} ${doorTopHeight1 + 56.17}
                ${doorLeftWidth1 + 39.66} ${doorTopHeight1 + 74.37}
                ${doorLeftWidth1 + 99.86} ${doorTopHeight1 + 74.37}
                ${doorLeftWidth1 + 99.86} ${doorTopHeight1 + 56.17}
                ${doorLeftWidth1 + 39.66} ${doorTopHeight1 + 56.17}
                `"/>
      <path id="inox-4"
            v-if="showInox"
            data-name="inox"
            class="cls-10"
            :d="`
            M${doorLeftWidth1 + 983.31},${doorTopHeight1 + 7301}
            v28
            h69.78
            v-28
            H${doorLeftWidth1 + 983.31}
            Z
            m65,23.07
            H${doorLeftWidth1 + 988.12}
            v-18.25
            h60.14
            v18.25
            Z
            `"
            transform="translate(-948.44 -7249.7)"/>
      <!-- Molding BEGIN -->
      <g id="Moldings" v-if="showMolding">
        <g id="Molding-1" data-name="Molding">
          <polygon
            id="Solid-3T"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 99.5} ${doorTopHeight1 + 55.88}
              ${doorLeftWidth1 + 39.69} ${doorTopHeight1 + 55.88}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 51.5}
              ${doorLeftWidth1 + 104.38} ${doorTopHeight1 + 51.5}
              ${doorLeftWidth1 + 99.5} ${doorTopHeight1 + 55.88}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 99.5} ${doorTopHeight1 + 55.88}
              ${doorLeftWidth1 + 39.69} ${doorTopHeight1 + 55.88}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 51.5}
              ${doorLeftWidth1 + 104.38} ${doorTopHeight1 + 51.5}
              ${doorLeftWidth1 + 99.5} ${doorTopHeight1 + 55.88}
            `"/>
          <polygon
            id="Solid-3B"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.69} ${doorTopHeight1 + 75.16}
              ${doorLeftWidth1 + 99.61} ${doorTopHeight1 + 75.16}
              ${doorLeftWidth1 + 104.38} ${doorTopHeight1 + 79.54}
              ${doorLeftWidth1 + 34.93} ${doorTopHeight1 + 79.54}
              ${doorLeftWidth1 + 39.69} ${doorTopHeight1 + 75.16}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 39.69} ${doorTopHeight1 + 75.16}
              ${doorLeftWidth1 + 99.61} ${doorTopHeight1 + 75.16}
              ${doorLeftWidth1 + 104.38} ${doorTopHeight1 + 79.54}
              ${doorLeftWidth1 + 34.93} ${doorTopHeight1 + 79.54}
              ${doorLeftWidth1 + 39.69} ${doorTopHeight1 + 75.16}
            `"/>
          <polygon
            id="Solid-3L" 
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.69} ${doorTopHeight1 + 55.88}
              ${doorLeftWidth1 + 39.69} ${doorTopHeight1 + 75.16}
              ${doorLeftWidth1 + 34.93} ${doorTopHeight1 + 79.54}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 51.5}
              ${doorLeftWidth1 + 39.69} ${doorTopHeight1 + 55.88}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 39.69} ${doorTopHeight1 + 55.88}
              ${doorLeftWidth1 + 39.69} ${doorTopHeight1 + 75.16}
              ${doorLeftWidth1 + 34.93} ${doorTopHeight1 + 79.54}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 51.5}
              ${doorLeftWidth1 + 39.69} ${doorTopHeight1 + 55.88}
            `"/>
          <polygon
            id="Solid-3R" 
            class="Solid" 
            :points="`
              ${doorLeftWidth1 + 99.56} ${doorTopHeight1 + 75.16}
              ${doorLeftWidth1 + 99.44} ${doorTopHeight1 + 55.88}
              ${doorLeftWidth1 + 104.38} ${doorTopHeight1 + 51.5}
              ${doorLeftWidth1 + 104.38} ${doorTopHeight1 + 79.54}
              ${doorLeftWidth1 + 99.56} ${doorTopHeight1 + 75.16}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 99.56} ${doorTopHeight1 + 75.16}
              ${doorLeftWidth1 + 99.44} ${doorTopHeight1 + 55.88}
              ${doorLeftWidth1 + 104.38} ${doorTopHeight1 + 51.5}
              ${doorLeftWidth1 + 104.38} ${doorTopHeight1 + 79.54}
              ${doorLeftWidth1 + 99.56} ${doorTopHeight1 + 75.16}
            `"/>
        </g>
        <g id="Molding-2L" data-name="Molding">
          <polygon
            id="Solid-1T"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 58.63} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 39.8} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 63.67} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 58.63} ${doorTopHeight1 + 97.94}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 58.63} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 39.8} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 63.67} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 58.63} ${doorTopHeight1 + 97.94}
            `"/>
          <polygon
            id="Solid-1B"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.97} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 58.8} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 63.67} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 39.97} ${doorTopHeight1 + 200.6}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 39.97} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 58.8} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 63.67} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 39.97} ${doorTopHeight1 + 200.6}
            `"/>
          <polygon
            id="Solid-1L"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.8} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 39.97} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 39.8} ${doorTopHeight1 + 97.94}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 39.8} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 39.97} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 39.8} ${doorTopHeight1 + 97.94}
            `"/>
          <polygon
            id="Solid-1R"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 58.8} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 58.63} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 63.67} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 63.67} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 58.8} ${doorTopHeight1 + 200.6}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 58.8} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 58.63} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 63.67} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 63.67} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 58.8} ${doorTopHeight1 + 200.6}
            `"/>
        </g>
        <g id="Molding-2R" data-name="Molding">
          <polygon
            id="Solid-2T"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 99.72} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 81.24} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 76.46} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 99.72} ${doorTopHeight1 + 97.94}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 99.72} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 81.24} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 76.46} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 99.72} ${doorTopHeight1 + 97.94}
            `"/>
          <polygon
            id="Solid-2B"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 81.41} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 99.89} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 76.46} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 81.41} ${doorTopHeight1 + 200.6}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 81.41} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 99.89} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 76.46} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 81.41} ${doorTopHeight1 + 200.6}
            `"/>
          <polygon
            id="Solid-2L"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 81.24} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 81.41} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 76.46} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 76.46} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 81.24} ${doorTopHeight1 + 97.94}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 81.24} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 81.41} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 76.46} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 76.46} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 81.24} ${doorTopHeight1 + 97.94}
            `"/>
          <polygon
            id="Solid-2R"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 99.89} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 99.72} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 99.89} ${doorTopHeight1 + 200.6}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 99.89} ${doorTopHeight1 + 200.6}
              ${doorLeftWidth1 + 99.72} ${doorTopHeight1 + 97.94}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 93.5}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 205.04}
              ${doorLeftWidth1 + 99.89} ${doorTopHeight1 + 200.6}
            `"/>
        </g>
        <g id="Molding-3" data-name="Molding">
          <polygon
            id="Solid-4T"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 99.78} ${doorTopHeight1 + 223.6}
              ${doorLeftWidth1 + 39.7} ${doorTopHeight1 + 223.6}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 219.22}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 219.22}
              ${doorLeftWidth1 + 99.78} ${doorTopHeight1 + 223.6}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 99.78} ${doorTopHeight1 + 223.6}
              ${doorLeftWidth1 + 39.7} ${doorTopHeight1 + 223.6}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 219.22}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 219.22}
              ${doorLeftWidth1 + 99.78} ${doorTopHeight1 + 223.6}
            `"/>
          <polygon
            id="Solid-4B"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.7} ${doorTopHeight1 + 242.87}
              ${doorLeftWidth1 + 99.89} ${doorTopHeight1 + 242.87}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 247.25}
              ${doorLeftWidth1 + 34.93} ${doorTopHeight1 + 247.25}
              ${doorLeftWidth1 + 39.7} ${doorTopHeight1 + 242.87}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 39.7} ${doorTopHeight1 + 242.87}
              ${doorLeftWidth1 + 99.89} ${doorTopHeight1 + 242.87}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 247.25}
              ${doorLeftWidth1 + 34.93} ${doorTopHeight1 + 247.25}
              ${doorLeftWidth1 + 39.7} ${doorTopHeight1 + 242.87}
            `"/>
          <polygon
            id="Solid-4L"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 39.7} ${doorTopHeight1 + 223.6}
              ${doorLeftWidth1 + 39.7} ${doorTopHeight1 + 242.87}
              ${doorLeftWidth1 + 34.93} ${doorTopHeight1 + 247.25}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 219.22}
              ${doorLeftWidth1 + 39.7} ${doorTopHeight1 + 223.6}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 39.7} ${doorTopHeight1 + 223.6}
              ${doorLeftWidth1 + 39.7} ${doorTopHeight1 + 242.87}
              ${doorLeftWidth1 + 34.93} ${doorTopHeight1 + 247.25}
              ${doorLeftWidth1 + 34.92} ${doorTopHeight1 + 219.22}
              ${doorLeftWidth1 + 39.7} ${doorTopHeight1 + 223.6}
            `"/>
          <polygon
            id="Solid-4R"
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 99.84} ${doorTopHeight1 + 242.87}
              ${doorLeftWidth1 + 99.72} ${doorTopHeight1 + 223.6}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 219.22}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 247.25}
              ${doorLeftWidth1 + 99.84} ${doorTopHeight1 + 242.87}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 99.84} ${doorTopHeight1 + 242.87}
              ${doorLeftWidth1 + 99.72} ${doorTopHeight1 + 223.6}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 219.22}
              ${doorLeftWidth1 + 104.67} ${doorTopHeight1 + 247.25}
              ${doorLeftWidth1 + 99.84} ${doorTopHeight1 + 242.87}
            `"/>
        </g>
      </g>
      <!-- Molding END -->
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showMolding',
    'doorSolidColor',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
